<template>
        <div ref="editorCellRef" class="o365-body-cell o365-editor-cell" :class="editorClass" :style="style">
            <component v-if="editor.isSlot" :is="editor.node"
                :modelValue="row" :row="row" 
                :column="column" 
                :class="column.editorClass" 
                ref="editorRef"
                v-bind="column.cellEditorParams">
            </component>
            <component v-else :is="editor.node"
                v-model="row[column.field]"
                
                :class="column.editorClass"
                ref="editorRef"
                v-bind="column.cellEditorParams, conditionalProps">
            </component>
        </div>

        <Teleport :to="cellContainer">
            <div ref="editorAnchorRef" class="grid-editor-anchor"
                :style="anchorStyle"></div>
        </Teleport>
</template>

<script setup lang="ts">
import useBaseGridControl from 'o365.vue.composables.Grid.BaseControlInject.ts';
import { dataGridEditorCellControlKey } from 'o365.modules.vue.injectionKeys.js';
import { provide, computed, ref, Teleport, resolveComponent, onMounted, onUpdated, onBeforeUnmount, defineAsyncComponent } from 'vue';
import { createPopper } from 'popper';

const OTextEditor = defineAsyncComponent(async () => {
    const { OTextEditor }  = await import('o365.vue.components.inputEditors.jsx');
    return OTextEditor;
});
const ONumberEditor = defineAsyncComponent(async () => {
    const { ONumberEditor }  = await import('o365.vue.components.inputEditors.jsx');
    return ONumberEditor;
});
const OBitEditor = defineAsyncComponent(async () => {
    const { OBitEditor }  = await import('o365.vue.components.inputEditors.jsx');
    return OBitEditor;
});
const ODateEditor = defineAsyncComponent(async () => {
    const { ODateEditor }  = await import('o365.vue.components.inputEditors.jsx');
    return ODateEditor;
});
const InputEditors = { OTextEditor, ONumberEditor, OBitEditor, ODateEditor };

interface Props {
    data?: any[],
    activeCell?: string,
    gridContainer?: HTMLElement,

};

const props = defineProps<Props>();

const gridControl = useBaseGridControl();


const expandedPopup = ref(false);
function activatePopupMode() {
    expandedPopup.value = true;
}
function deactivatePopupMode() {
    expandedPopup.value = false;

}
provide(dataGridEditorCellControlKey, {
    expandedPopup,
    activatePopupMode,
    deactivatePopupMode
});

const editorCellRef = ref(null);
const editorAnchorRef = ref(null);

const colIndex = computed(() => {
    return parseInt(props.activeCell.split('_')[0]);
});
const rowIndex = computed(() => {
    return parseInt(props.activeCell.split('_')[1]);
});

const column = computed(() => {
    return gridControl.value.columns.columns[colIndex.value];
});

const row = computed(() => {
    return props.data[rowIndex.value] ?? {};
});

const cellContainer = computed(() => {
    return getCellContainer(column.value.pinned);
});

function getCellContainer(pinned) {
    switch (pinned) {
        case 'left':
            return props.gridContainer.querySelector('.o365-body-left-pinned-cols');
        case 'right':
            return props.gridContainer.querySelector('.o365-body-right-pinned-cols');
        default:
            return props.gridContainer.querySelector('.o365-body-center-cols-container');
    }
};

const editorClass = computed(() => {
    return {
        'o365-editor-popup': expandedPopup.value,
        'p-2': expandedPopup.value,
        'rounded-0': expandedPopup.value,
        'card': expandedPopup.value,
        'shadow': expandedPopup.value,
        'o365-cell-range-single-cell': !expandedPopup.value
    };
});

const style = computed(() => {
    //const rowIndex = parseInt(props.activeCell.split('-')[1]);
    //const pos = rowIndex * 34;

    return {
        'min-width': expandedPopup.value ? '400px' : null,
        'position': 'absolute',
        'width': column.value.width + 'px',
        //'left': column.value.left + 'px',
        'height': expandedPopup.value ? 'auto' : '34px',
        //'transform': `translateY(${pos}px)`,
    }
});

const anchorStyle = computed(() => {
    const rowIndex = parseInt(props.activeCell.split('_')[1]);
    const pos = rowIndex * 34;

    return {
        'position': 'absolute',
        'left': column.value.left + 'px',
        'transform': `translateY(${pos}px)`,
    }

});

const editor = computed(() => {
    deactivatePopupMode();
    if (column.value.slots.editor) {
        return { node: column.value.slots.editor, isSlot: true };
    } else {
        if (typeof column.value.cellEditor === 'string') {
            let editor = InputEditors[column.value.cellEditor];
            let node = editor ?? resolveComponent(column.value.cellEditor);
            return { node: node, isSlot: false };
        } else {
            return { node: column.value.cellEditor, isSlot: false };
        }
    }
});

const editorProps = computed(() => {
    if (typeof editor.value === 'object' && editor.value?.node?.props) {
        if (Array.isArray(editor.value.node.props)) {
            return editor.value.node.props;
        } else {
            return Object.keys(editor.value.node.props);
        }
    } else {
        return [];
    }
});

const conditionalProps = computed(() => {
    const obj: any = {};
    if (editorProps.value) {    
        if (editorProps .value.includes('row')) {
            obj.row = row.value;
        }
        if (editorProps .value.includes('column')) {
            obj.column = column.value;
        }
    }
    return obj;
});

let popperInstance = null;
onMounted(() => {
    popperInstance = createPopper(editorAnchorRef.value, editorCellRef.value, {
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'flip',
                enabled: true,
                options: {
                    flipVariations: false,
                    fallbackPlacements: ['top-start']
                }
            },
            {
                name: 'offset',
                options: {
                    offset: ({placement}) => {
                        if (placement === 'top-start') {
                            return [0, -33];
                        } else {
                            return [];
                        }
                    }
                }
            },
        ],
    });
});

onUpdated(() => {
    popperInstance.state.elements.reference = editorAnchorRef.value;
    popperInstance.update();
});

onBeforeUnmount(() => {
    popperInstance.destroy();
});

defineExpose({ colIndex });
</script>